<template>
  <div class="faq-wrapper">
    <div class="faq-box">
      <div class="faq-title wow animate__fadeInLeft hidden-xs-only">FAQ</div>
      <div class="qa-title wow animate__fadeInLeft hidden-sm-and-up">Q&A</div>
      <div class="faq-content">
        <el-row :gutter="0">
          <el-col :xs="24" :sm="24" :md="14" :lg="15" :xl="16">
            <div class="faq-card wow animate__fadeInUp">
          <el-collapse v-model="activeName" accordion v-for="(item, index) in faqList" :key="index" >
            <el-collapse-item :name="index">
              <template slot="title">
                <div class="faq-item-q">Q</div> 
                <div class="faq-item-title">{{ item.question }}</div>
              </template>
              <div class="faq-a">
                <div class="faq-a-title">A</div>
                <div class="faq-a-content">
                  <div>{{ item.answer }}</div>
                  <div class="faq-list" v-for="items in item.list" :key="items">
                    {{ items }}
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="10" :lg="9" :xl="8">
            <div class="customer-card wow animate__fadeInRight">
              <div class="customer-content">
                <div class="customer-title">Customer Service - siap membantu</div>
                <div class="wow animate__fadeInUp customer-tip">
                  <div>Hubungi Kami di Hari Senin – Minggu</div>
                  <div>Jam 09:00 – 17:30 WIB</div>
                </div>
              </div>
              <div class="customer-btn-card">
                <div class="wow animate__fadeInUp customer-info-card">
                  <img src="../assets/v3/faq/faq-logo.png" class="faq-logo">
                  <div class="customer-info-right">
                    <img src="../assets/v3/faq/halo.png">
                    <div>Pelanggan yang terhormat, sangat senang bisa melayani Anda</div>
                  </div>
                </div>
                <div class="wow animate__fadeInLeft customer-btn" @click="callPhone('1500-006')">
                    <img src="../assets/v3/faq/telephone1.png" alt="telephone">
                    <div>1500-006</div>
                  </div>
                  <div class="wow animate__fadeInRight customer-btn" @click="callPhone('0889-1500-006')">
                    <img src="../assets/v3/faq/telephone2.png" alt="telephone">
                    <div>0889-1500-006</div>
                  </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import info from '@/utils/options'
import {WOW} from 'wowjs'
export default {
  data() {
    return {
      activeName: 0,
      faqList: info.faqList
    };
  },
  mounted(){
    this.$nextTick(() => {
      var wow = new WOW({
        animateClass: 'animated',
        offset: 0,
      })
      wow.init()
    })
    sessionStorage.setItem('activeTab', '4')
  },
  methods:{
    callPhone(phone){
      window.location.href = `tel:${phone}`
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-wrapper{
  width: 100%;
  min-height: 100vh;
  background: #C6E8ED;
  padding: 89px 0 77px 0;
  box-sizing: border-box;
  .faq-box{
    max-width: 1400px;
    margin: 0 auto;
  }
  .faq-title{
    font-size: 58px;
    font-weight: bold;
    margin: 0 0 40px 60px;
  }
  .qa-title{
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 10px 34px;
  }
  .faq-content{
    display: flex;
    .faq-card{
      height: auto;
      margin-right: 30px;
      padding: 60px 130px 90px 68px;
      box-sizing: border-box;
      background: #fff;
      .faq-item-q{
        font-size: 20px;
        font-weight: 600;
        margin-right: 16px;
      }
      .faq-item-title{
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
      .faq-a{
        display: flex;
        margin-top: 10px;
      }
      .faq-a-title{
        font-size: 20px;
        font-weight: 600;
        color: #1D1D1D;
        margin: 0 16px 0 5px;
      }
      .faq-a-content{
        font-size: 12px;
        color: #AEAEAE;
      }
    }
    .customer-card{
      .customer-content{
        width: 100%;
        height: 564px;
        background: url("../assets/v3/faq/custom.png") no-repeat;
        background-size: cover;
        position: relative;
        .customer-title{
          width: 424px;
          font-size: 30px;
          font-weight: 600;
          line-height: 44px;
          padding: 28px 0 0 42px;
          box-sizing: border-box;
        }
        .customer-tip{
          width: 100%;
          position: absolute;
          color: #28543F;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          bottom: 10px;
          text-align: center;
        }
      }
      .customer-btn-card{
        width: 100%;
        background: #fff;
        padding: 42px 0 40px 38px;
        .customer-info-card{
          display: flex;
          align-items: center;
          .faq-logo{
            width: 98px;
            margin-right: 18px;
          }
          .customer-info-right{
            img{
              width: 48px;
              margin-bottom: 6px;
            }
            div{
              width: 300px;
              font-size: 14px;
            }
          }
        }
        .customer-btn{
            display: flex;
            width: 316px;
            height: 66px;
            align-items: center;
            background: #ACDDB1;
            border: 1px solid #28543F;
            font-size: 20px;
            border-radius: 33px;
            padding-left: 54px;
            margin: 10px auto 0 auto;
            cursor: pointer;
            img{
              width: 40px;
              margin-right: 14px;
            }
          }
      }
    }
  }
}
</style>